.imad {
  background: white;
  height: 300px;
  border: 5px solid #172d8e;
  border-radius: 5%;
}

.koko14 {
  background: #000;
  color: #fff;
  font-size: 14px;
  line-height: 1.4;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.form-group1 {
  margin-top: 16px;
}

.form1 {
  max-width: 320px;
  margin: 32px auto;
}

.input1 {
  background: #333;
  border: 0;
  padding: 16px;
  width: 100%;
  border-radius: 6px;
  color: #fff;
  width: 290px;
}

.input1 input[type="file"] {
  background: transparent;
  padding-left: 0;
}

.butts {
  width: 320px;
  display: inline-block;
  border: 0;
  padding: 10px;
  background: #fff;
  border-radius: 6px;
}
