@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}
.imgk {
  margin-top: 100px;
  width: 1600px;
  height: 600px;
}
.awssld {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 1000px !important;
  margin-bottom: -500px;
  z-index: 0;
}
.home-slider1.awssld__container figure, .awssld__content, .awssld__box{
  height: 70% !important;
}
.awssld__controls button {

  top: calc(30% - (0.5 * var(--control-button-height))) !important;}
  .awssld__content {
    background-color: #edeeed;
  }
  .awssld__controls button{
    background: transparent !important;
  }
  
.bottom-container,
.top-container {
  width: 15vw;
    height: 35vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 43vh;
    font-size: 25px;
    left: 45vw;
    padding: 1vw;

}

#neonShadow{
  height: 50px;
  width: 210px;
  border: none;
  border-radius: 50px;
  transition: 0.3s;
  background-color: rgba(156, 161, 160,0.3);
  animation: glow 1s infinite;
  transition: 0.5s;
  position: relative;
  top: -350px;
  left: 700px;
  /* align-items: center; */
  color: white;
}


#neonShadow:hover{
  transform:translateX(-20px)rotate(30deg);
  border-radius:5px;
  background-color:#c3bacc;
  transition:0.5s;
}


@keyframes glow{
  0%{
  box-shadow: 5px 5px 20px rgb(93, 52, 168),-5px -5px 20px rgb(93, 52, 168);}
  
  50%{
  box-shadow: 5px 5px 20px rgb(81, 224, 210),-5px -5px 20px rgb(81, 224, 210)
  }
  100%{
  box-shadow: 5px 5px 20px rgb(93, 52, 168),-5px -5px 20px rgb(93, 52, 168)
  }
}



#neonShadow1{
  height: 50px;
  width: 210px;
  border: none;
  border-radius: 50px;
  transition: 0.3s;
  background-color: rgba(156, 161, 160,0.3);
  animation: glow 1s infinite;
  transition: 0.5s;
  position: relative;
  top: -200px;
  left: 100px;
  /* align-items: center; */
  color: white;
}
#neonShadow1:hover{
  transform:translateX(-20px)rotate(30deg);
  border-radius:5px;
  background-color:#c3bacc;
  transition:0.5s;
}

@keyframes glow{
  0%{
  box-shadow: 5px 5px 20px rgb(93, 52, 168),-5px -5px 20px rgb(93, 52, 168);}
  
  50%{
  box-shadow: 5px 5px 20px rgb(81, 224, 210),-5px -5px 20px rgb(81, 224, 210)
  }
  100%{
  box-shadow: 5px 5px 20px rgb(93, 52, 168),-5px -5px 20px rgb(93, 52, 168)
  }
}


#neonShadow2{
  height: 50px;
  width: 210px;
  border: none;
  border-radius: 50px;
  transition: 0.3s;
  background-color: rgba(156, 161, 160,0.3);
  animation: glow 1s infinite;
  transition: 0.5s;
  position: relative;
  top: -350px;
  left: 670px;
  color: white;
}


#neonShadow2:hover{
  transform:translateX(-20px)rotate(30deg);
  border-radius:5px;
  background-color:#c3bacc;
  transition:0.5s;
}


@keyframes glow{
  0%{
  box-shadow: 5px 5px 20px rgb(93, 52, 168),-5px -5px 20px rgb(93, 52, 168);}
  
  50%{
  box-shadow: 5px 5px 20px rgb(81, 224, 210),-5px -5px 20px rgb(81, 224, 210)
  }
  100%{
  box-shadow: 5px 5px 20px rgb(93, 52, 168),-5px -5px 20px rgb(93, 52, 168)
  }
}



.bottom-container {
  color: #232323;
}

.top-container {
  background-color: #395a87;
  color: white;
  clip-path: circle(13% at 85% 50%);
  animation: circleMove 5s ease-in-out infinite;
}

@keyframes circleMove {
  0%, 100% {
    clip-path: circle(13% at 85% 50%);
  }
  50% {
    clip-path: circle(13% at 15% 50%);
  }
}

.center{
  position: absolute;
  text-align: center;
  top: 90%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.center h1{
  color: rgba(255,0,0,0.1);
  font-size: 100px;
  text-transform: uppercase;
  font-weight: 700;
  background-size: cover;
  background-image: url(https://image.freepik.com/free-vector/construction-tools-background_76844-500.jpg);
  -webkit-background-clip: text;
  animation: background-text-animation 15s linear infinite;
}
@keyframes background-text-animation {
  0%{
    background-position: left 0px top 50%;
  }
  50%{
    background-position: left 1500px top 50%;
  }
  100%{
    background-position: left 0px top 50%;
  }
}

.mess h1 {
  color: #333;
  font-family: tahoma;
  font-size: 15px;
  left: -10px;
  font-weight: bold;
  line-height: 2.5;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  width: 1000px;
 
}
.mess {
  position: absolute;
    top: 300px;
    left: 500px;
    width: 520px;
    padding: 20px;
    background: white;
    height: 150px;
}
.mess h1 span {
  font-size: 20px;
  margin-left: 30px;
}

.message {
  background-color: #1c1c97;
  color: white;
  display: block;
  font-weight: 900;
  overflow: hidden;
  position: absolute;
  padding-left: 0.5rem;
  top: 0.2rem;
  left: 270px;
  animation: openclose 5s ease-in-out infinite;
  
}

.word1 {
  font-family: tahoma;
}

@keyframes openclose {
  0% {
    top: 0.2rem;
    width: 0;
  }
  5% {
    width: 0;
  }
  15% {
    width: 230px;
  }
  30% {
    top: 0.2rem;
    width: 230px;
  }
  33% {
    top: 0.2rem;
    width: 0;
  }
  35% {
    top: 0.2rem;
    width: 0;
  }
  38% {
    top: -4.5rem;
    
  }
  48% {
    top: -4.5rem;
    width: 190px;
  }
  62% {
    top: -4.5rem;
    width: 190px;
  }
  66% {
    top: -4.5rem;
    width: 0;
    text-indent: 0;
  }
  71% {
    top: -9rem;
    width: 0;
    text-indent: 5px;
  }
  86% {
    top: -9rem;
    width: 285px;
  }
  95% {
    top: -9rem;
    width: 285px;
  }
  98% {
    top: -9rem;
    width: 0;
    text-indent: 5px;
  }
  100% {
    top: 0;
    width: 0;
    text-indent: 0;
  }
}

/* .home,
.services,
.products,
.contact-us,
.sign-up,
.marketing,
.consulting {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
} */
.ctn {
  overflow: scroll;
}
@media (max-width: 500px){
  /* .ctn{
    touch-action: auto;
    -ms-touch-action: auto;
  } */
 
}
.button1 {
  position: relative;
  top: -350px;
  left: 700px;
  background: #f8cd00;
  box-sizing: border-box;
  border-radius: 0.6rem;
  align-self: center;
  font-size: 17px;
  font-weight: 400;
  margin: 20px;
  padding: 20px 40px;
  text-align: center;
  color: white;
  font-family: Pangolin;
  font-weight: bold;
  cursor: pointer;
}
.button1:hover {
  box-shadow: 0 3px 3px 0 #3d5e89;
  transform: translateY(-10px);
  background: lavender;
  border: none;
  color: rgb(22, 6, 26);
  font-size: large;
}
.button2{
  position: relative;
    top: -250px;
    left: 120px;
    background: #f8cd00;
    box-sizing: border-box;
    border-radius: 0.6rem;
    align-self: center;
    font-size: 17px;
    font-weight: 400;
    margin: 20px;
    padding: 20px 40px;
    text-align: center;
    color: white;
    font-family: Pangolin;
    font-weight: bold;
    cursor: pointer;
}
.button2:hover {
  box-shadow: 0 3px 3px 0 #3d5e89;
  transform: translateY(-10px);
  background: lavender;
  border: none;
  color: rgb(22, 6, 26);
  font-size: large; 
}

.containerab {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  margin-top: 300px;
  margin-bottom: 300px;
  padding: 0px;
  width: 100%;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-lighter {font-weight: 200;
color: #1a2aa8;}
.text-bold {font-weight: 600;}
.text-muted {font-weight: 400;font-size: 20px;color: hsl(229, 6%, 66%);}
header {display: flex;flex-direction: column;justify-content: center;align-items: center;}
header h3 {font-size: 30px;margin: 0px auto;}
header p {text-align: center;}

main {margin: 20px auto;display: flex;flex-direction: row;column-gap: 25px;}
main .section2 {display: flex;flex-direction: column;justify-content: center;align-items: center;}
.card2 {width: 320px;padding: 35px;box-shadow: 0.5px 0.5px 12px 0px #80808042;box-sizing: border-box;border-radius: 5px;margin: 15px auto;transition: transform .2s;}
.card2:hover {
  transform: scale(1.1);
}
.card2.card2-supervisor {border-top: 3px solid  hsl(183deg 80% 38%);}
.card2.card2-team_builder {border-top: 3px solid  hsl(239deg 37% 46%);}
.card2.card2-karma {border-top: 3px solid hsl(33deg 92% 54%);}
.card2.card2-calculator {border-top: 3px solid hsl(0deg 100% 50%);}
.card2 .card2-body {

}
.card2 .card2-body .cards2-title {font-size: 20px;}
.card2 .card2-body .card2-text {font-size: 15px; margin-top: 8px;color: black;}
.card2 .card2-footer {margin-top: 20px;}
.card2 .card2-footer .card2-img {display: flex;flex-direction: row-reverse;}
.card2 .card2-footer .card2-img img {width: 150px;height: 100px;}


@media only screen and (max-width: 1440px) {

}
@media only screen and (max-width: 1000px) {
    body {
        height: unset;
        margin: 60px auto;
    }
    header h3 {text-align: center;font-size: 22px;}
    main {flex-direction: column;}
}
@media only screen and (max-width: 540px) {
  .imgk {
    margin-top: 100px;
    width: 400px;
 
  }
  .containerab {
    margin-bottom: 0px;
    margin-top: 200px;
  }
  
}
@media only screen and (max-width: 540px) {
    .koko22{
        margin-top:-70px
    }
  }

  @media only screen and (max-width: 640px) {
    .koko22{
        margin-top:-60px
    }
  }


  @media only screen and (max-width: 370px) {
    .koko22{
        margin-top:-60px
    }
  }

