
.footer{
    color: #fff;
    background-image: linear-gradient(90deg, rgb(10 22 73) 0%, rgb(67 56 202) 100%);
    position: relative;
    width: 100%;
    height: 100%;
    top:100px;
    border-top-left-radius: 70px;
    font-size: 0.8rem;
  }
  
  
  
  @media (max-width:960px) {
    footer{
      width: 100%;
      top: 120px;
    }
    
    .main{
    margin: 50px;
   display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr 1fr;
  }
    
    .logo{
      grid-column: 1/3;
    }
    
    .office{
      grid-column: 3/5;
    }
    .link{
      grid-column: 1/3;
    }
    
      
  }
  
  .kokof{
    margin-top:100px;
  }
  
  
  
  @media (max-width:660px){
    .main{
      gap:10px;
      margin: 0;
  
    }
    
    .icons{
      display: flex;
    }
    
   
    
    .row{
      grid-column: 1/4;
    }
  }
  
  
  
  
  
  
  
  .manik{
    width: 120px;
  }
  .main{
    margin: 50px;
    padding-top: 20px;
    padding-bottom: 50px;
    display: grid;
    gap: 50px;
    grid-template-columns: repeat(4, 1fr );
  }
  .row{
   margin-top: 50px;
  }
  .footer-header{
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .office-des{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .office a{
    text-decoration: none;
    color: #98A8F8;
    
  }
  .office a:hover{
    color: #fff;
  }
  .num{
    font-size: 1rem;
    font-weight: 500;
  }
  
  .link-des{
    display: flex;
    flex-direction: column;
    gap:10px;
  }
  .link-des a{
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .link-des a:hover{
    color: #FB2576;
    transition: linear 0.3s;
  }
  
  .btn-know{
    text-decoration: none;
    background-color: #fff;
    padding: 10px;
    position: relative;
    top: 30px;
    background-color: transparent;
    border: 1px solid #98A8F8;
    color: #98A8F8;
    text-transform: uppercase;
    border-radius:10px;
    
  }
  
  
  .btn-know:hover{
    color: white; 
    background-color: #98A8F8;
    transition: linear 0.3s;
  }
  .subcribe{
    display: flex;
  }
  input[type=mail]{
    border: none;
    padding-bottom: 5px;
    border-bottom: 1px solid #fff;
    background-color: transparent;
    font-size: 0.8rem;
  }
  .sub-icon{
    margin: 7px;
    margin-right: 15px;
    margin-left: 0;
    font-size: 1.5rem;
  }
  .ri-arrow-right-line{
    margin-left: 0;
    
  }
  .icons {
    display: flex;
    flex-direction: row;
  }
  .icons a{
    text-decoration: none;
    color: #fff;
    border: 1px solid #fff;
    justify-content: center;
    padding: 10px;
    border-radius: 50%;
    align-items: center;
    font-size: 1.1rem;
    margin: 2%;
   
  }
  
  
  .social-icon{
    position: relative;
    /* top:2px; */
  }
  .icons a:hover{
    color: #20a4d4;
    font-size: 30px;
    background-color: white;
    border-color: #20a4d4;
  }
  .newsletter-des{
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  
  .copyright{
    text-align: center;
  }
  
  hr{
    width: 90%;
    margin: 2px auto;
    opacity: 40%;
    border: 0.01px solid white;
    
  }
  
  .copyright p{
    padding: 20px;
  }
  
  
  
  